import * as React from "react"
import { Ul, Li, Span, Div, Button} from "@reflexjs/components"
import { Link, Icon, useBreakpointIndex } from "@reflexjs/gatsby-theme-core"
import { Block } from "@reflexjs/gatsby-theme-block"
import { useDropdown } from "./use-dropdown"
import { useNav } from "./use-nav"

export const NavMenuLink = ({
  value,
  url,
  type,
  isOpen,
  dropdown,
  isHeader,
  depth,
  ...props
}) => {
  const activeStyle = {
    color: isHeader ? "primary" : "white",
  }

  props = {
    ...props,
  }

  const linkStyle = {
    fontFamily: isHeader ? "heading" : "body",
    color: isHeader ? "text" : "white",
    hoverColor: isHeader ? "primary" : "white",
    fontSize: ["lg", "null", "null", "lg", "lg", "2xl" ],
    fontWeight: depth === 1 && !isHeader ? "lighter" : "semibold",
    d: "block",
    w: "full",
    px: [4, 0],
    py: dropdown && 3,
    textAlign: ["left", !dropdown ? "left" : "center"],
    ...(isOpen && activeStyle),
  }

  return type === "link" ? (
    <Link href={url} activeStyle={activeStyle} {...linkStyle} {...props}>
      {value}
    </Link>
  ) : (
    <Span { ...( isHeader && 
      { tabIndex: "0" }, !isHeader && { mb: "4|8", mt: "8|0" } ) } {...linkStyle} {...props}>
      {value}
    </Span>
  )
}

export const NavMenuSubNav = ({ items, dropdown, isHeader, isOpen, ...props }) => {
  if (!items?.length) return null

  const [block] = items
  const isBlock = items[0].type === "block"

  const dropdownStyle = {
    fontWeight: "bold",
    color: isHeader ? "text" : "white",
    position: [null, "absolute"],
    maxHeight: [isOpen ? "100vh" : 0, "none"],
    boxShadow: [null, "lg"],
    bg: ["background"],
    borderRadius: [null, "3xl"],
    overflow: "hidden",
    opacity: [1, isOpen ? 1 : 0],
    visibility: ["visible", isOpen ? "visible" : "hidden"],
    transition: "all .15s ease-in",
    transform: ["none", "translateX(-50%)"],
    left: "120%",
    top: isOpen ? "100%" : "130%",
    zIndex: 500,

    ul: {
      py: 3,
      minWidth: "300px",

      li: {
        mt: 0,

        a: {
          py: 2,
          px: 6,
          // fontWeight: "semibold",

          "&:hover": {
            bg: "light",
          },
        },
      },
    },
  }

  const item = isBlock ? (
    <Block src={block.value} {...props} />
  ) : (
    <Ul listStyle="none" p="0" m="0">
      {items.map((item, index) => (
        <NavMenuItem key={index} depth={1} dropdown={false} isHeader={isHeader} {...item} />
      ))}
    </Ul>
  )

  return dropdown ? <Div sx={dropdownStyle}>{item}</Div> : item
}

export const NavMenuItem = ({
  value,
  url,
  items,
  type,
  dropdown,
  isHeader,
  trigger,
  vertical,
  expand,
  depth = 0,
  ...props
}) => {
  const [dropdownRef, isOpen, open, close] = useDropdown()
  const isMobile = useBreakpointIndex() <= 2
  if (!value) return null

  const usingHover = trigger === "hover"

  props = {
    ...props,
    ...(usingHover &&
      !isMobile && {
        onMouseEnter: open,
        onMouseLeave: close,
      }),
  }

  return (
    <Li
      ref={dropdownRef}
      position="relative"
      mt={[vertical || !dropdown ? 2 : 0, depth === 1 ? 2 : 0]}
      {...props}
    >
      <NavMenuLink
        value={value}
        url={url}
        type={type}
        cursor={url ? "pointer" : "default"}
        dropdown={dropdown}
        isHeader={isHeader}
        depth={depth}
        isOpen={isOpen}
        {...(dropdown && !usingHover && { onClick: isOpen ? close : open })}
        onClick={() => (isOpen ? close() : open())}
      />
      {expand && items?.length && (
        <React.Fragment>
          {dropdown && (
            <Button
              variant="icon"
              onClick={() => (isOpen ? close() : open())}
              d={["block", "none"]}
              position="absolute"
              right="6"
              top="6px"
              rounded="none"
              h="12"
              w="12"
            >
              <Icon size="7" name={isOpen ? "arrow-up" : "arrow-down"} />
            </Button>
          )}
          <NavMenuSubNav
            pl="5"
            items={items}
            dropdown={dropdown}
            isHeader={isHeader}
            depth={depth}
            isOpen={isOpen || !dropdown}
          />
        </React.Fragment>
      )}
    </Li>
  )
}

export const NavMenu = ({
  name,
  dropdown = true,
  isHeader = "true",
  trigger = "hover",
  vertical = false,
  expand = true,
  style,
  ...props
}) => {
  const [nav] = useNav(name)

  if (!nav || !nav.items?.length) return null

  const gridStyle = `repeat(${nav.items.length}, auto)`

  style = {
    d: "grid",
    listStyle: "none",
    p: 0,
    row: [gridStyle, vertical ? gridStyle : "none"],
    col: [null, vertical ? "none" : gridStyle],
    gap: [vertical || !dropdown ? 8 : 0, vertical ? 8 : 4],
    my: 0,
    mx: [-4, 0],
    ...style,
  }

  return (
    <Ul {...style} {...props}>
      {nav.items.map((item, index) => (
        <NavMenuItem
          key={index}
          isHeader={isHeader}
          dropdown={dropdown}
          trigger={trigger}
          vertical={vertical}
          expand={expand}
          {...item}
        />
      ))}
    </Ul>
  )
}
