import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const Card = ({
  image,
  alt,
  subtitle,
  description
}) => <Div mdxType="Div">
    <Image src={image} alt={alt} aspectRatio={3 / 2} w="full" h={["50vh", "50vh", "50vh", "auto"]} boxShadow="xl" border="3" borderColor="white" mdxType="Image" />
    <Div px="2" pb="10|8|12" pt="6|8" mdxType="Div">
      <H3 fontWeight="medium" fontFamily="heading" mb="3" mdxType="H3">
        {subtitle}
      </H3>
      <P mdxType="P">{description}</P>
    </Div>
  </Div>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Div = makeShortcode("Div");
const Image = makeShortcode("Image");
const H3 = makeShortcode("H3");
const P = makeShortcode("P");
const layoutProps = {
  Card,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      