//MailchimpForm.jsx
import React from 'react';
import { Div, Form, Input, Label, VisuallyHidden, P, H1, Flexbox, Container, Button } from "@reflexjs/components"
import addToMailchimp from 'gatsby-plugin-mailchimp'
export default class SubscribeForm extends React.Component {
    state = {
        email: '',
        message: ''
    };
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email);
        this.setState({ message: result.msg });
    };
    render() {
        return (
      <>
        <Container>
            <Flexbox
            flexDirection="column"
            textAlign="center"
            py="16"
            px="10"
            bg="white"
            border="1"
            >
            <H1 mb="2">Stay in the loop.</H1>
            <P mb="10" fontSize="lg" textAlign="center">
                Sign up to my newsletter to get notified about seasonal events and
                special offers!
            </P>
            <Form name="subscribeForm" method="POST" id="subscribe-form" onSubmit={this.handleSubmit}  mb="4" w="full|auto">
                <Flexbox flexDirection="column" w="full|auto" alignItems="start">
                    <Div fontSize="lg" fontWeight="bold" mb="3" dangerouslySetInnerHTML={{ __html: this.state.message}} />
                    <Div d="grid" col="1|350px auto" gap="6" w="full|auto">
                        <VisuallyHidden>
                            <Label htmlFor="foutlined-email-input">Email</Label>
                        </VisuallyHidden>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter Email Address..."
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            id="outlined-email-input"
                            label="Email"
                            autoComplete="email"
                            variant="lg"
                        />
                        <Button type="submit" variant="primary">
                            Subscribe
                        </Button>
                    </Div>
                </Flexbox>
            </Form>
            <P
                fontSize="xs"
                lineHeight="tight"
                w="full"
                maxWidth="558px"
                textAlign="left"
            >
                I will not spam you as well as I will keep your email address secure!
            </P>
            </Flexbox>
        </Container>
      </>
    );
}
}