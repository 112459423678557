import * as React from "react"
import { Main } from "@reflexjs/components"
import { Metatags } from "@reflexjs/gatsby-plugin-metatags"
import { MDXProvider } from "@reflexjs/gatsby-theme-core"
import { Block } from "@reflexjs/gatsby-theme-block"
import 'focus-visible/dist/focus-visible'

export const Layout = ({
  header = "header",
  footer = "footer",
  children,
  pathname,
}) => (
  <React.Fragment>
    <Metatags pathname={pathname} />
    <MDXProvider>
      {header && <Block src={header} />}
      <Main
      sx={{
        '&.js-focus-visible :focus:not([data-focus-visible-added])' : {
          outline: "none",
          boxShadow: "none",
        },
        '& button, a, div, span, svg' : {
         WebkitTapHighlightColor: "transparent",
        WebkitTouchCallout: 'none',
         WebkitUserSelect: "none",
         KhtmlUserSelect: "none",
         MozUserSelect: "none",
         MsUserSelect: "none",
         UserSelect: "none",
        }
      }}
      >{children}</Main>
      {footer && <Block src={footer} />}
    </MDXProvider>
  </React.Fragment>
)
